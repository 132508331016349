import authClient from '@/axiosClients/authClient'
import authLanguajeClient from '@/axiosClients/authLanguageClient'
import {errorHttpMessage} from '@/utils/errorDecodeUtil.js'
import authTenant from "@/axiosClients/authTenant";
import store from "@/store";
import {setAccessToken} from "@/modules/auth/store/mutations";

export const validateUser = async ({commit}, userLogin) => {
    try {
        const {data: result} = await authClient.post('/validate-username', {username: userLogin.username, email: userLogin.username})
        const {data, message, error, ask_business_name} = result.result

        return {message, error, ask_business_name}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const updatePermissionsUserSesion = ({commit}, permissions) => {
    commit('updatePermissionsUserSesion', permissions)
}

export const login = async ({commit}, userLogin) => {
    try {
        const {data: result} = await authClient.post('/login', {username: userLogin.username, email: userLogin.username, password: userLogin.password, business: userLogin.business, is_need_business: userLogin.is_need_business})
        const {message, error, ask_business_name} = result.result

        if (!error) {
            const {accessToken, refreshToken, expiresIn, route_redirect, date, user, host, is_landlord: isLandlord} = result.result.data
            commit('login', {user, accessToken, refreshToken, expiresIn, route_redirect, date, host, isLandlord})
        }

        return {message, error, ask_business_name}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}


export const loginFromSSOResponse = async ({commit}, acessTokenResponse) => {
    try {

        commit('setTenantHost', acessTokenResponse.host)
        commit('setAccessToken', acessTokenResponse.accessToken)

        const {data: result} = await authTenant.post('/login-token', {})
        const {message, error, ask_business_name} = result.result

        if (!error) {
            const {accessToken, refreshToken, expiresIn, route_redirect, date, user, host, is_landlord: isLandlord} = result.result.data
            commit('login', {user, accessToken, refreshToken, expiresIn, route_redirect, date, host, isLandlord})
        }

        return {message, error, ask_business_name}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}


export const hasSSOConfigured = async ({commit}) => {
    try {

        const {data: result} = await authClient.post('/has-sso-configured',)
        const {message, error, data} = result.result

        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const verifyTokenStatuss = async ({commit}, token) => {
    try {
        //const { valid, response } = await authClient.post('/verify-token', {token})
        const {valid, response} = await new Promise(function (resolve, reject) {
            setTimeout(() => resolve({valid: true, response: 'token valido'}), 1000)
        })
        if (valid) {
            return valid
        }
    } catch (ex) {
        //console.log('Ha ocurrido un error al validar el token: ', ex)
    }
}

export const logout = async ({commit, getters}) => {
    try {
        const token = getters.getToken
        const {data: result} = await authTenant.post('/logout', null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const {data, message, error} = result.result
        if (!error) {
            commit('logout')
        }
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const sendEmailRecoveryPassword = async ({commit}, user) => {
    try {
        commit('requestEmailRecoveryPassword', user.username)
        const {data: result} = await authClient.post('/recover-password', {username: user.username, business: user.business, is_need_business: user.is_need_business})
        const {message, error, type, data_identificator, password_reset_token, security_questions_available} = result.result
        return {message, error, type, data_identificator, password_reset_token, security_questions_available}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }

}

export const updatePasswordVerificationCode = async ({commit, getters}, payload) => {
    try {
        const data = {
            password_reset_token: getters.getPasswordResetToken,
            password: payload.password1.value,
            password_confirmation: payload.password2.value,
            code: payload.verificationCode.value
        }
        const {data: result} = await authClient.post('/reset-password', data)
        const {message, error} = result.result
        if (!error) {
            commit('requestEmailRecoveryPassword', null)
        }
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const updatePasswordWithoutVerificationCode = async ({commit, getters}, payload) => {

    try {
        const data = {
            password_reset_token: getters.getPasswordResetToken,
            password: payload.password1.value,
            password_confirmation: payload.password2.value,
        }
        const {data: result} = await authClient.post('/reset-password-without-code', data)
        const {message, error} = result.result

        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }

}

export const validateUserSecretQuestions = async ({commit, getters}, questions) => {

    try {

        const data = {
            password_reset_token: getters.getPasswordResetToken,
            questions: questions,
        }

        const {data: result} = await authClient.post('/validate-security-questions', data)
        const {message, error} = result.result

        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, error: true}
    }

}

export const refreshToken = async ({commit, getters}) => {

    try {

        const refreshToken = getters.getRefreshToken

        if (refreshToken != null) {
            const {data: result} = await authTenant.post('/refreshtoken', {refreshToken: refreshToken})
            const {data, message, error} = result.result
            const {token} = data

            if (!error) {
                commit('updateToken', {token})
            }

            return {message, error}
        } else {
            return {message: "Refresh token doesnt exists", error: true}
        }

    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const clearAuthToken = async ({commit, getters}) => {

    try {

        commit('logout')

    } catch (ex) {
        return {message: "Cant remove sessionStorage", error: true}
    }
}

export const getQuestionsByUser = async ({commit, getters}, passwordResetToken) => {

    try {

        const {data: result} = await authClient.post('/get-security-questions', {password_reset_token: passwordResetToken})
        const {data, message, error} = result.result

        commit('setLang', data.lang)

        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}


export const getDefaultLang = async ({commit, getters}) => {

    try {

        const {data: result} = await authLanguajeClient.get('/get-default-lang')
        
        const {data, message, error} = result.result

        return {data, message, error}

    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

